import React, { useEffect, useCallback, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Axios from "axios";
import toastr from "toastr";
import 'toastr/build/toastr.css';
// import { useNavigation } from '@react-navigation/native';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules

import { Autoplay } from "swiper/modules";
// import required modules
import { Navigation } from "swiper/modules";
import Header from "../Header/Header";

const Innerpage = () => {

  const { id } = useParams();
  const [framedatadata, setFramedata] = useState("");
  const [getCategoryName, setCategoryName] = useState("");

  const [frameId, setFramId] = useState("");
  const [frameCateId, setFrameCateId] = useState("");
  const [isReq, setIsReq] = useState(false);
  const [getCategory, setCategory] = useState(true);
  const [getFrameTitle, setFrameTitle] = useState('');

  const [selectedSize, setSelectedSize] = useState(null);
  const [allframes, setAllframes] = useState([]);
  const [allSaveFrames, setallSaveFrames] = useState([]);
  const [frameSizes, setFrameSizes] = useState([]);
  const [framSize, setFrameSize] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageScroll, setImagesSroll] = useState([]);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isOffsetSelected, setIsOffSet] = useState(false);
  const [getFeatureId, setFeatureId] = useState(false);
  const [getFeatureName, setFeatureName] = useState(false);
  // const navigation = useNavigation();

  const location = useLocation();
  const scrollToHash = useCallback(() => {
    const hash = location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      section?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [location.hash]);

  useEffect(() => {
    framemaindata(id);
    scrollToHash();
  }, [scrollToHash]);
  useEffect(() => {
    let images = [];
    // setImagesSroll(images);
  }, []);
  function goBack() {
    window.history.back();
  }
  function framedata(frameimages) {

    setImagesSroll(frameimages);
  }

  const showImage = (index) => {

    setCurrentIndex(index);
  };

  const previousImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imageScroll.length) % imageScroll.length
    );
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    description: '',
  });

  // Event handler for input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Event handler for form submission
  const handleSubmit = (e) => {
    
    e.preventDefault();
    scrollToHash();
    const url =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/scheduleAppointment" ;

    const headerTypes = {
      "content-type": "application/json",
    };
    Axios.post(url, formData, { headers: headerTypes })
      .then((res) => {
  console.log(res.data.message)
  
      })
      .catch((error) => {
        
        console.log(error);
      });
    // Access the form data in 'formData'
    console.log(formData);
    // Add your logic for form submission here
  };


  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageScroll.length);
  };
  function getImageType(image) {
    // Check if the image URL ends with ".mp4"
    return image.toLowerCase().endsWith(".mp4");
  }
  function framemaindata(frameId) {
    scrollToHash();
    const url =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFrame/" + frameId;

    const headerTypes = {
      "content-type": "application/json",
    };
    Axios.get(url, { headers: headerTypes })
      .then((res) => {

        const modifiedArray = res.data[0].base64Images.map((url) => ({ image: url }));
        modifiedArray.forEach((obj) => {
          obj.imageType = getImageType(obj.image);
        });
        console.log(modifiedArray)
        res.data[0].base64Images = modifiedArray

        setCategoryName(res.data[0].categories)
        setCategory(res.data[0].categories)
        
        // setFramedata(res.data[0]);
        setFrameTitle(res.data[0].title)
        if (res.data[0].categories === 'TANJORE PAINTINGS') {
          setIsReq(false)
        } else {
          setIsReq(true)
        }
        if (res.data[0].categories === 'TANJORE PAINTINGS' || res.data[0].categories === 'OFFSET PRINTING') {
          setCategory(false)
        } else {
          setCategory(true)
        }
        setFrameCateId(res.data[0].categoryId)
        setFramId(res.data[0].categoryId)
        getFeatureList(frameId, res.data[0].categoryId)

        // const modifiedBase64Array = addPrefixToBase64Array(
        //   res.data[0].base64Images
        // );
        // framedata(modifiedBase64Array);

        framedata(res.data[0].base64Images);
        getFav(frameId)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getFav(frameId) {

    const url =
      `https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFav?frameId=${frameId}`;

    const headerTypes = {
      "content-type": "application/json",
    };
    const authToken = localStorage.getItem("token");

    const config = {
      Authorization: authToken,
    };

    Axios.get(url, { headers: config })
      .then((res) => {
        setIsFavorited(res.data.favourite);

      })
      .catch((error) => {
        console.log(error);
      });
  }

  function addPrefixToBase64Array(base64Array) {
    // Map over the array and perform the required operations
    const resultArray = base64Array.map((base64) => {
      // Step 1: Decode the Base64 content
      const decodedData = atob(base64);

      // Step 2: Prepend "info/" to the decoded content
      const modifiedData = "data:image/jpeg;base64/" + decodedData;

      // Step 3 (optional): Encode the modified content back to Base64
      const modifiedBase64 = btoa(modifiedData);
      const modifiedBase = "data:image/jpeg;base64" + modifiedBase64;

      return modifiedBase; // You can also return modifiedData if Base64 encoding is not needed.
    });

    return resultArray;
  }

  useEffect(() => {
    // api call
    const url = "https://aanvikshikiartapi.aanvikshiki.art/api/frame/allframes";

    const headerTypes = {
      "content-type": "application/json",
    };
    Axios.get(url, { headers: headerTypes })
      .then((res) => {
        // console.log(res.data);
        setAllframes(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  // add favorites api
  const favoritedata = (e) => {

    e.preventDefault();
    const authToken = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const frameId = framedatadata._id;
    const body = {
      userId: userId,
      frameId: id,
    };

    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/addfavourite";

    // Retrieve the token from local storage

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, body, config)
        .then((res) => {
          console.log(res);

          toastr.success(res.data.message)
          console.log(res.data.message);
          if (isFavorited === false) {
            setIsFavorited(true);
          }
          if (isFavorited === true) {
            setIsFavorited(false);
          }
          getFav(frameId)
        })
        .catch((err) => {
          console.error("Error adding address:", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      alert('Please login')
      // Handle the case where the token is not available (user is not authenticated)
    }
  };

  // add to bag post api
  const getFeatureList = (frameId, data) => {

    const authToken = localStorage.getItem("token");
    const apiUrl =
      `https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFeatureList?categoryId=${data}`;

    // Retrieve the token from local storage

    // if (authToken) {
    
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.get(apiUrl, config)
        .then((res) => {

          let information = []
          console.log('resresres', res);
          res.data.forEach((value) => {
            if (value.categoryId === data) {
              information.push(value)
            }
          });
          getFrameSizesByCategoryId(frameId, res.data[0]._id)
          setallSaveFrames(information)
        })
        .catch((err) => {
          console.error("Error adding address:", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    // } else {
    //   // Handle the case where the token is not available (user is not authenticated)
    // }
  };
  const getFrameSizesByCategoryId = (frameId, CategoryFeatureId) => {

    const authToken = localStorage.getItem("token");
    const apiUrl =
      `https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFrameSizesByCategoryId?frameId=${id}&CategoryFeatureId=${CategoryFeatureId}`;

    // Retrieve the token from local storage
    // if (authToken) {
    
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.get(apiUrl, config)
        .then((res) => {
          let information = []
          console.log('resresres', res);
          // res.data.forEach((value) => {
          //   // setFrameSizes = value
          //   if (value.categoryId === data) {
          //     information.push(value)
          //   }
          // })
          // setFrameSizes(res.data)
          let sortSizes = res.data.sort((a, b) => a.price - b.price);
          setFrameSize(sortSizes)  
          

          setFramedata(res.data[0]);
        })
        .catch((err) => {
          console.error("Error adding address:", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    // } else {
    //   // Handle the case where the token is not available (user is not authenticated)
    // }
  };
  const addtobag = (e) => {
    e.preventDefault();
    if (selectedSize) {
      const userId = localStorage.getItem("userId");
      const frameId = id;
      const categoryId = framedatadata.categoryId;
      const sizeid = selectedSize;
      const apiUrl =
        "https://aanvikshikiartapi.aanvikshiki.art/api/user/addCart";

      // Retrieve the token from local storage
      const authToken = localStorage.getItem("token");
      <Header datas={authToken} />
      if (authToken) {
        const config = {
          headers: {
            Authorization: authToken,
          },
        };
        const body = {
          userId: userId,
          frameId: frameId,
          categoryId: frameCateId,
          sizeid: sizeid,
          getFeatureName: getFeatureName,
          categoryFeatureId: getFeatureId
        };

        Axios.post(apiUrl, body, config)
          .then((res) => {
            console.log(res);
            toastr.success(res.data.message)
          })
          .catch((err) => {
            console.error("Error adding frame to cart:", err);

            // Handle the error, display an error message, or perform other error-handling actions.
          });
      } else {
        // Handle the case where the token is not available (user is not authenticated)
      }
    } else {
      alert("Please select size");
    }
  };

  const handleSizeSelect = (sizeId) => {
    setSelectedSize(sizeId);
    console.log(allSaveFrames)
    if (allSaveFrames[0].name === 'TANJORE PAINTINGS') {
      setIsOffSet(true)
    }
    framSize.forEach((value) => {
      if (value._id === sizeId) {
        console.log("framedatadata", framedatadata)
        setFramedata(prevState => ({
          ...prevState, // Spread the previous state
          price: value.price // Update the price property with the new value
        }));
      }
    })
    // setFramedata({price: 123})
  };
  const CheckSizeSelect = (sizeId) => {

    allSaveFrames.forEach((value) => {
      if (value._id === sizeId) {
        setFeatureId(value._id)
        setFeatureName(value.name)
        console.log(value)
        if (value.name === 'TANJORE PAINTINGS' || value.name === 'OFFSET PRINTING') {
          setCategory(false)
          setIsOffSet(true)
        }
        else if (value.name === 'HAND PAINTING') {
          setIsOffSet(true)
        }
        else {
          setIsOffSet(false)
          setCategory(true)
        }
      }
    })
    // if (sizeId === '655347546c26267c5a68a7cc' || sizeId === '65eed8b6dbc486070d4ad2e1') {
    //   setIsOffSet(true)
    // }
    // else {
    //   setIsOffSet(false)
    // }
    console.log(sizeId)
    console.log('frame Id', framedatadata._id)
    getFrameSizesByCategoryId(framedatadata._id, sizeId)
  };
  return (
    <>
      <div className="cardinnerpage-content">
        <div className="innerpage-top">
          <div className="row">
            <div className="col-md-12">
              <div className="edit-page5">
                <div className="container">
                  <h3 className="page-title">
                    <NavLink className="page-titlespan" to="/landing">
                      {" "}
                      Home <span className="arrow">{">"}</span> {" "}
                    </NavLink>{" "}
                    <NavLink className="page-titlespan" onClick={goBack}>
                      {" "}
                      {getCategoryName} <span className="arrow">{">"}</span> {" "}
                    </NavLink>
                    {getFrameTitle}
                  </h3>
                  <div className="row">
                    <div className="col-md-7">
                      {
                        getCategoryName !== "PRINTING"?(
                           <div className="gallery">
                        <div className="thumbnails">
                          {imageScroll.map((item, index) => (
                            <div className="image-inside" key={index}>
                              {/* <img
                                className="thumbnail"
                                src={src.image}
                                onClick={() => showImage(index)}
                                alt={`Thumbnail ${index + 1}`}
                              /> */}
                              {item.imageType ? (
                                <video onClick={() => showImage(index)} className="thumbnail"  >
                                  <source src={item.image} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img src={item.image} onClick={() => showImage(index)} className="thumbnail" alt='' />
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="main-image">
                          <i
                            className="fas fa-chevron-left prev-icon"
                            onClick={previousImage}
                          />
                          <div className="main-imagedata">
                            {/* <img
                              id="mainImg"
                              className="image-inside"
                              // src={imageScroll[currentIndex]}
                              src={imageScroll[currentIndex]?.image}
                              alt="no-data"
                            /> */}
                            {imageScroll[currentIndex]?.imageType ? (
                              // Display video tag if imageType is true
                              <video
                                id="mainVideo"
                                className="video-inside" autoPlay
                                controls
                                controlsList="nodownload"
                                disablePictureInPicture // This disables the picture-in-picture mode, which might also show a download option
                                onError={(e) => console.error("Video error:", e)}
                              // Add other video attributes as needed
                              >
                                <source src={imageScroll[currentIndex]?.image} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              // Display image tag if imageType is false or undefined
                              <img
                                id="mainImg"
                                className="image-inside"
                                src={imageScroll[currentIndex]?.image}
                                alt="no-data"
                              />
                            )}
                          </div>
                          <i
                            className="fas fa-chevron-right next-icon"
                            onClick={nextImage}
                          />
                        </div>
                      </div>
                        )
                        :(
                          <div className="image-withoutcarousal">
                               <img
                                id="mainImg"
                                className="image-inside"
                                src={imageScroll[currentIndex]?.image}
                                alt="no-data"
                              />
                            </div>
                       
                        )
                      }
                     
                    </div>
                    <div className="col-md-5">
                      <div className="editRight-Data">
                        <span className="price-title">
                          {getFrameTitle}
                          {/* {framedatadata?.title} */}
                        </span>
                        <div>
                          {getCategory ? (
                            <div>
                              <p className="price-money">
                                MRP : <span className="prices">₹ {framedatadata?.price}</span>
                                
                              </p>
                              <p className="travelers-data">incl. of taxes</p>
                              <p className="travelers-data">
                                (Also includes all applicable duties)
                              </p>
                            </div>
                          ) : (
                            null
                          )}
                        </div>

                        {/* <h3 className="painting-size">Painting in Inches</h3> */}
                        <h3 className="painting-size">Painting in Sizes</h3>
                        <form>
                          <div
                            className="btn-group btn-group-toggle painting-ul"
                            data-toggle="buttons"
                          >
                            <div className="btn-group btn-group-toggle painting-ul painting-list" data-toggle="buttons">
                              <select
                                className="form-select price-selectd"
                                value={selectedSize}
                                onChange={(e) => handleSizeSelect(e.target.value)}
                              >
                                <option value="" defaultChecked>Select size</option>
                                {framSize &&
                                  framSize.map((sizes, index) => (
                                    <option key={index} value={sizes._id}>
                                      {sizes.size}
                                    </option>

                                  ))}
                              </select>
                            </div>
                            {/* <select className="form-select price-selectd1" aria-label="Default select example">
                              <option selected="">Open this select menu</option>
                              {
                                allSaveFrames.map((sizes, index) => (
                                  <option key={index} value={sizes._id}>
                                    {sizes.name}
                                  </option>
                                ))}
                            </select> */}
                            {isReq ? (
                              <select onChange={(e) => CheckSizeSelect(e.target.value)} className="form-select price-selectd1" aria-label="Default select example">
                                <option selected="">Open this select menu</option>
                                {allSaveFrames.map((sizes, index) => (
                                  <option key={index} value={sizes._id}>
                                    {sizes.name}
                                  </option>
                                ))}
                              </select>
                            ) : null}
                          </div>
                          {isOffsetSelected ? (
                            <NavLink data-bs-toggle="modal" data-bs-target="#exampleModal" className="bag-btn">
                              Enquire now
                            </NavLink>
                          ) : (
                            <div>
                              {/* <p className="price-money">
                                MRP : <span>₹ {framedatadata?.price}</span>
                              </p>
                              <p className="travelers-data">incl. of taxes</p>
                              <p className="travelers-data">
                                (Also includes all applicable duties)
                              </p> */}
                              <p>
                                <NavLink className="bag-btn" onClick={addtobag}>
                                  Add to Bag
                                </NavLink>
                                <NavLink className="fav-btn" onClick={favoritedata}>
                                  <i className={isFavorited ? "fa-solid fa-heart fullheart" : "fa-regular fa-heart"}></i>
                                  Favorite
                                </NavLink>
                              </p>
                            </div>
                          )}
                         
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* <div className="media profilemedia-data mt-5">
                            <img
                              src="/images/landing/review-img.png"
                              className="profilemedia-img mr-3"
                              alt="no-data"
                            />
                            <div className="media-body">
                              <h5 className="profilemedia-title mt-0">
                                Aanvikshiki Arts
                              </h5>
                            </div>
                          </div>
                          <p className="profile-message">
                              At Aanvikshiki, our journey goes beyond mere business dealings; it's a profound odyssey into the realm of artistry, seamlessly blending passion, experience, and unwavering dedication. For more than a decade, we've stood at the forefront of the art industry, pioneering new frontiers and establishing benchmarks for excellence. Our narrative is one of relentless pursuit—of beauty, of significance, and of art's transcendent capacity to touch the deepest recesses of the human spirit.
                          </p> */}
                  <h3 className="painting-description">Painting Description</h3>
                  <p className="painting-para" dangerouslySetInnerHTML={{ __html: framedatadata?.description }}></p>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="images-listscrolls">
          <div className="container">
            <h3 className="arts-frame-title">You Might Also Like</h3>
            <Swiper
              direction={"horizontal"}
              centeredSlides={false}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
              grabCursor={true}
              freeMode={true}
              spaceBetween={50}
              scrollbar={{ draggable: true }}
              dir="ltr"
            >
              {allframes.map((frames, index) => (
                <SwiperSlide key={index}>
                  {" "}
                  <div
                    className="card-contents-inside-inner"
                    onClick={() => framemaindata(frames._id)}
                  >
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src={frames.image}
                        alt="no-data"
                      />

                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        {frames.title}
                      </p>

                      {/* <h5 className="price-card">₹ {frames.price}</h5> */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-button-prev">
              <i className="fa-solid fa-angle-left icon-data"></i>
            </div>
            <div className="swiper-button-next">
              <i className="fa-solid fa-angle-right icon-data"></i>{" "}

            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content enqiry-form">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Enquiry</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label enquiry-list">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label enquiry-list">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="form-label enquiry-list">
                  phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label enquiry-list">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="description"
                    name="description"
                    placeholder="Your message"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </div>
                <button type="submit" className="btn enquiry-btn">
                  Submit
                </button>
              </form>
            </div>
 
          </div>
        </div>
      </div>
    </>
  );
};

export default Innerpage;
