import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import toastr from "toastr";
import 'toastr/build/toastr.css';
import Axios from 'axios';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from 'swiper/modules';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useNavigate } from 'react-router-dom';
// import required modules   ₹ ₹

// import { Autoplay } from 'swiper';

import { Autoplay } from "swiper/modules";
import { Pagination, Navigation } from "swiper/modules";


const Landing = () => {
  const navigate = useNavigate();

  const sectionRef = useRef(null);
  const modalRefs = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  // contact us modal close
  const modalRef = useRef(null);
  const [name, setName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [getCateName, setCatoName] = useState("");
  const [allcategory, setAllcategory] = useState([]);
  const [allcategoryList, setAllcategorylist] = useState([]);
  const [allPanchangam, setPanchangam] = useState([]);
  const [allcategoryListNew, setAllcategorylistnew] = useState([]);
  const [getCategory, setCategory] = useState("");

  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    description: ''
  });
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  // contact us api
  useEffect(() => {
    let data = {
      categories: "PRINTING",
    };
    filterData(data);
  }, []);

  useEffect(() => {
    let data = {
      categories: "HAND PAINTING",
    };
    filterData2(data);
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = './js/home.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "phoneNumber") {
      setphoneNumber(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "description") {
      setDescription(value);
    }

    console.log(value)
  };

  const contactus = (e) => {
    e.preventDefault();

    const body = {
      name: name,
      phone: phoneNumber,
      email: email,
      comments: description,
    };

    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/scheduleAppointment";

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, body, config)
        .then((res) => {
          console.log(res);

          // Close the modal when the form is submitted successfully
          if (modalRef.current) {
            modalRef.current.click();
          }
        })
        .catch((err) => {
          console.error("Error contact us ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  };
  function gotomore() {
    window.location.href = `/frames/${getCateName}`;
  }

  const filterData = (data) => {
    const body = {
      filterId: data.categories,
    };

    setCategory(data.categories,)
    setCatoName(data.categories)
    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/filterByIdFifteen";
    Axios.post(apiUrl, body)
      .then((res) => {

        res.data.forEach((obj) => {
          obj.imageType = getImageType(obj.image);
        });
        setAllcategorylist(res?.data);
      })
      .catch((err) => {
        console.error("Error contact us ", err);
      });
  };
  const filterData2 = (data) => {
    const body = {
      filterId: data.categories,
    };
    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/filterByIdFifteen";
    Axios.post(apiUrl, body)
      .then((res) => {

        res.data.forEach((obj) => {
          obj.imageType = getImageType(obj.image);
        });

        setAllcategorylistnew(res?.data);
        // setCatoName()
      })
      .catch((err) => {
        console.error("Error contact us ", err);
      });
  };
  useEffect(() => {
    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/getCategories";
    Axios.get(apiUrl)
      .then((res) => {

        let result = []

        setCatoName(res.data[0].categories)
        res.data.forEach((value) => {
          if (value.categories !== 'HAND PAINTING') {
            result.push(value)
          }
          else {

          }
        })
        setAllcategory(result);
      })
      .catch((err) => {
        console.error("Error contact us ", err);
        // Handle the error, display an error message, or perform other error-handling actions.
      });
  }, []);
  function getImageType(image) {
    // Check if the image URL ends with ".mp4"
    return image.toLowerCase().endsWith(".mp4");
  }

  const handleNameChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      name: value
    });
  };

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      phoneNumber: value
    });
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      email: value
    });
  };

  const handleDescriptionChange = async (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      description: value
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);


    try {
      const response = await fetch('https://aanvikshikiartapi.aanvikshiki.art/api/user/scheduleAppointment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toastr.success("Email Sent sucessful")
        // alert('Form submitted successfully!');
        modalRef.current.click();
      } else {
        alert('Error submitting form');
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      // alert('Error submitting form');
      toastr.eror(error)
      console.error('Error:', error);
    }
    // Add logic to submit the form data or perform other actions here
  };
  useEffect(() => {
    getPanchangamImages()
  }, [])
  async function getPanchangamImages() {
    try {
      const response = await fetch('https://aanvikshikiartapi.aanvikshiki.art/api/frame/getPanchangam', {
        method: 'GET',
      });

      const data = await response.json();
      console.log(data)
      setPanchangam(data)
    } catch (error) {
      // alert('Error submitting form');
      toastr.eror(error)
      console.error('Error:', error);
    }
  }
  return (
    <>
      <div className="landing-content">
        <div className="create-accountcontent">
          <div className="container">
            <Swiper
              direction={"horizontal"}
              centeredSlides={false}
              pagination={true}
              autoplay={{
                delay: 6500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
              grabCursor={true}
              freeMode={true}
              spaceBetween={50}
              scrollbar={{ draggable: true }}
              dir="ltr"
            >
              {/* <SwiperSlide>
                <div className="topcarousel-data">
                  <div className="row justify-content-between">
                    <div className="col-md-6 d-flex align-items-center">
                      <div className="painting-left">
                        <div className="printing-para">
                          <span className="linespan"></span>
                          <p className="inside-paradata">TANJORE PAINTINGS </p>
                        </div>
                        <h3 className="painting-title">
                          Elevate Your Space with Timeless
                          <span className="painting-span"> Tanjore Art</span>
                        </h3>
                        <p className="painting-discription">
                          Discover the rich heritage and spiritual depth of
                          Tanjore paintings at Aanvikshiki. Our handcrafted
                          masterpieces blend artistry, history, and devotion to
                          create captivating artworks that stand the test of
                          time. Bring home a piece of India's artistic legacy
                          today.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="painting-right">
                        <div className="right-image">
                          <img
                            className="image-data"
                            src="./images/landing/venkateswara.webp"
                            alt="lakshmi-balaji"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
              <SwiperSlide>
                <div className="topcarousel-data">
                  <div className="row justify-content-between">
                    <div className="col-md-6 d-flex align-items-center">
                      <div className="painting-left">
                        <div className="printing-para">
                          <span className="linespan"></span>
                          <p className="inside-paradata">PRINTING </p>
                        </div>
                        <h3 className="painting-title">
                          At its Finest: Vivid and Lasting <br></br>
                          <span className="painting-span">
                            {" "}
                            Pigment Printing
                          </span>
                        </h3>
                        <p className="painting-discription">
                          Explore the world of pigment printing with
                          Aanvikshiki. Our use of high-quality pigments ensures
                          vibrant and durable artworks that retain their
                          brilliance for years. Experience the beauty of pigment
                          printing with us.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="painting-right">
                        <div className="right-image">

                          <img
                            className="image-data"
                            src="./images/landing/nijapadam12.jpg"
                            alt="lakshmi-balaji"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="topcarousel-data">
                  <div className="row justify-content-between">
                    <div className="col-md-6 d-flex align-items-center">
                      <div className="painting-left">
                        <div className="printing-para">
                          <span className="linespan"></span>
                          <p className="inside-paradata">TANJORE PAINTINGS </p>
                        </div>
                        <h3 className="painting-title">
                          Elevate Your Space with Timeless<br></br>
                          <span className="painting-span"> Tanjore Art</span>
                        </h3>
                        <p className="painting-discription">
                          Discover the rich heritage and spiritual depth of
                          Tanjore paintings at Aanvikshiki. Our handcrafted
                          masterpieces blend artistry, history, and devotion to
                          create captivating artworks that stand the test of
                          time. Bring home a piece of India's artistic legacy
                          today.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="painting-right">
                        <div className="right-image">
                          <img
                            className="image-data"
                            src="./images/landing/Balaji-lakshmi.jpg"
                            alt="lakshmi-balaji"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="topcarousel-data">
                  <div className="row justify-content-between">
                    <div className="col-md-6 d-flex align-items-center">
                      <div className="painting-left">
                        <div className="printing-para">
                          <span className="linespan"></span>
                          <p className="inside-paradata">PRINTING </p>
                        </div>
                        <h3 className="painting-title">
                          At its Finest: Vivid and Lasting <br></br>
                          <span className="painting-span">
                            {" "}
                            Pigment Printing
                          </span>
                        </h3>
                        <p className="painting-discription">
                          Explore the world of pigment printing with
                          Aanvikshiki. Our use of high-quality pigments ensures
                          vibrant and durable artworks that retain their
                          brilliance for years. Experience the beauty of pigment
                          printing with us.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="painting-right">
                        <div className="right-image">
                          <img
                            className="image-data"
                            src="./images/landing/Ganesh1.jpg"
                            alt="lakshmi-balaji"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="panchangam-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <h1 className="daily-pancham">Daily Updates</h1>

                <div className="daily-updates">
                  <>
                    <Swiper
                      direction={"horizontal"}
                      centeredSlides={false}
                      pagination={true}
                      autoplay={{
                        delay: 6500,
                        disableOnInteraction: false,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 1,
                          spaceBetween: 50,
                        },
                      }}
                      modules={[Autoplay, Pagination]}
                      className="mySwiper"
                      grabCursor={true}
                      freeMode={true}
                      spaceBetween={50}
                      scrollbar={{ draggable: true }}
                      dir="ltr"
                    >
                      <SwiperSlide>

                        <img className="daily-img" src="./images/landing/morning2.jpega"></img>

                      </SwiperSlide>
                      <SwiperSlide>

                        <img className="daily-img" src="./images/landing/morning.png"></img>

                      </SwiperSlide>
                      <SwiperSlide>

                        <img className="daily-img" src="./images/landing/morning1.png"></img>

                      </SwiperSlide>

                    </Swiper>
                  </>
                </div>
              </div>
              <div className="col-md-6">
                <h1 className="daily-pancham">Daily Panchangam</h1>
                <div className="daily-updates">
                  <>
                    <Swiper
                      direction="horizontal"
                      centeredSlides={false}
                      pagination={true}
                      autoplay={{
                        delay: 6500,
                        disableOnInteraction: false,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 1,
                          spaceBetween: 50,
                        },
                      }}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                      grabCursor={true}
                      freeMode={true}
                      spaceBetween={50}
                      navigation={false}
                      scrollbar={{ draggable: true }}
                      dir="ltr"
                    >
                      {allPanchangam.map((item, index) => (
                        <SwiperSlide key={index}>
                          <img className="daily-img" src={item.image} alt={`Slide ${index + 1}`} />
                        </SwiperSlide>
                      ))}
                    </Swiper>

                  </>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="About-us-content" ref={sectionRef}>
          <div className="containerrr">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="about-img"
                  src="./images/landing/about-venky-img.webp"
                  alt="nodata"
                />
              </div>
              <div className="col-md-6">
                <div className="about-content">
                  <h1 className="about-head">
                    {" "}
                    <span>About Us</span>
                  </h1>
                  <p className="about-para">
                    At Aanvikshiki, we are more than just a business; we are the
                    culmination of our passion, experience, and commitment to
                    the world of art. We have established ourselves as pioneers
                    in the art industry, with a legacy spanning a decade. Our
                    journey has been guided by a commitment to imbue every
                    creation with soul, divinity, and faith. Our team of artists
                    has over 40 years of combined experience, bringing a wealth
                    of knowledge and originality to their craft.
                  </p>
                  <h1 className="time-head">
                    Tanjore Painting: Where Beauty Meets Timelessness
                  </h1>
                  <p className="tanjore-para">
                    Aanvikshiki Tanjore painting, a cherished art form hailing
                    from the heart of South India, is a testament to the magical
                    synergy of artistry and heritage. As these exquisite
                    hand-driven artworks mature with age, they don't just tell a
                    story; they become a valuable part of it. Our skilled
                    artisans employ a combination of techniques to bring
                    religious deities, mythological scenes, and historical
                    narratives to life. These hand-driven artworks are renowned
                    for their intricate gold leaf or paint embellishments that
                    add a touch of opulence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popular-category-content">
          <div className="containerrr">
            <h3 className="category-title">
              <span>Popular Categories</span>
            </h3>
            <div className="frames-catogerys">
              <ul
                className="nav nav-tabs painting-tabs1"
                id="myTab1"
                role="tablist"
              >
                {allcategory.map((value, index) => (
                  <li className="nav-item insidepainting-tabs" key={index}>
                    <button
                      className={`nav-link tabslist-paintings1 ${index === activeTab ? "active" : ""
                        }`}
                      id={`tab-${index}`}
                      data-toggle="tab"
                      data-target={`#tab-content-${index}`}
                      type="button"
                      role="tab"
                      aria-controls={`tab-content-${index}`}
                      aria-selected={index === activeTab ? "true" : "false"}
                      onClick={() => filterData(value)}
                    >
                      {value.categories}
                    </button>
                  </li>
                ))}
              </ul>
              {/* <NavLink className="all-frames">All Frames</NavLink> */}
            </div>

            <div className="containerrr">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="tab-content tabslistcontent-paintings"
                    id="myTabContent"
                  >
                    <div
                      className="tab-pane tabslistcontent-inside fade show active"
                      id="home1"
                      role="tabpanel"
                      aria-labelledby="home-tab1"
                    >
                      <div className="row">
                        {allcategoryList.length === 0 ? (
                          <h3>No items are available</h3>
                        ) : (

                          <ul className="card-main-home">
                            {allcategoryList.map((info, index) => (
                              <li className="card-box-landing" key={index}>
                                <NavLink
                                  to={`/card-inner/${info._id}`}
                                  className="card-contents-inside-main"
                                >
                                  <div className="card card-inside">
                                    {/* <img
                                      className="card-images"
                                      src={`data:image/jpeg;base64,${info.image}`}
                                      alt="no-data"
                                    /> */}
                                    {info.imageType ? (
                                      <video className="card-images" width="320" height="240" controls>
                                        <source src={info.image} type="video/mp4" />
                                      </video>
                                    ) : (
                                      <img className="card-images" src={info.image} alt='img url' />
                                    )}

                                  </div>
                                  <div className="card-body card-body-inside">
                                    <p className="card-title insidecard-balajititle">
                                      {info.title}
                                    </p>
                                    {/* <p className="card-text insidecard-balajipara">
              {info.description}
            </p> */}

                                    {getCategory !== 'TANJORE PAINTINGS' ? (
                                      // <h5 className="price-card">Rs. {info.price}</h5>
                                      null
                                    ) : (
                                      null
                                    )}
                                    {/* <h5 className="price-card">Rs. {info.price}</h5> */}
                                    {/* <h5 className="ratings"><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i><span className="reviews">5 reviews</span></h5> */}
                                  </div>
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NavLink className="viewmore-btn" onClick={gotomore}>View More</NavLink>
        </div>
        <div className="most-selling-content">
          <div className="containerrr">
            <h3 className="category-title">
              <span>Most Selling Products</span>
            </h3>
          </div>

          <div className="most-sellingdata">
            <div className="card mostselling-card">
              <div className="containerrr">
                <div className="row g-0">
                  <div className="col-md-5">
                    <div className="mostselling-image">
                      <img
                        src="./images/landing/Balaji-lakshmi.jpg"
                        className="img-fluid rounded-start mostselling-imageinside"
                        alt="no-data"
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="card-body mostselling-body">
                      <div className="inside-carddata">
                        <h5 className="card-title bestcolletion-title">
                          Best Collection
                        </h5>
                        <h5 className="card-title bestcolletion-title1">
                          Tanjore Painting
                        </h5>
                        <h5 className="card-title bestcolletion-title2">
                          Frame and Material
                        </h5>
                        {/* <h5 className="card-title bestcolletion-title3">
                          Rs.  1,50,000.00
                        </h5> */}
                        <ul
                          class="nav nav-tabs bestcollection-tabs"
                          id="myTab"
                          role="tablist"
                        >
                          <li
                            className="nav-item bestselling-tablist"
                            role="presentation"
                          >
                            <button
                              class="nav-link bestselling-tabbtn active"
                              id="description-tab"
                              data-toggle="tab"
                              data-target="#description"
                              type="button"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                            >
                              Description
                            </button>
                          </li>
                          <li
                            className="nav-item bestselling-tablist"
                            role="presentation"
                          >
                            <button
                              className="nav-link bestselling-tabbtn"
                              id="details-tab"
                              data-toggle="tab"
                              data-target="#details"
                              type="button"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                            >
                              Details
                            </button>
                          </li>
                          {/* <li
                            class="nav-item bestselling-tablist"
                            role="presentation"
                          >
                            <button
                              class="nav-link bestselling-tabbtn"
                              id="shipping-tab"
                              data-toggle="tab"
                              data-target="#shipping"
                              type="button"
                              role="tab"
                              aria-controls="contact"
                              aria-selected="false"
                            >
                              Shipping
                            </button>
                          </li> */}
                        </ul>
                        <div
                          className="tab-content mostselling-contents"
                          id="myTabContent"
                        >
                          <div
                            className="tab-pane content-listsmostselling fade show active"
                            id="description"
                            role="tabpanel"
                            aria-labelledby="description-tab"
                          >
                            At Aanvikshiki, we immerse you in the enchanting world of Tanjore Art, where tradition meets artistic brilliance. Our centerpiece, the Tanjore Lord Balaji, is a masterpiece of precision and devotion, crafted by the renowned Mr. A.R. Muthukumar and his team. Their expertise in human anatomy elevates their art to extraordinary levels of realism and detail, capturing the essence of divine beauty. Experience the magic of meticulous artistry and cultural heritage with us.
                          </div>
                          <div
                            className="tab-pane  content-listsmostselling fade"
                            id="details"
                            role="tabpanel"
                            aria-labelledby="details-tab"
                          >
                            Tanjore paintings boasts handcrafted perfection,
                            adorned with genuine gold leaf, and semi-precious
                            stones. Available in various designs, it's a symbol
                            of timeless beauty and spiritual significance.
                          </div>
                          {/* <div
                            class="tab-pane fade"
                            id="shipping"
                            role="tabpanel"
                            aria-labelledby="shipping-tab"
                          >
                            Enjoy free shipping on Tirupati Lighting orders over
                            Rs. 1,00,000. Your divine masterpiece will be carefully
                            packaged and delivered to your doorstep, ensuring it
                            arrives in pristine condition.
                          </div> */}
                        </div>
                        <NavLink className="btn addbag-btn" to={"/tanjaour/"}>
                          Go To Product
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-service-section">
          <div className="containerrr">
            <div className="row">
              <div className="col-md-12">
                <div className="service-content">
                  <h3 className="service-title">
                    <span>People Say About Us</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ourservices-data">
          <div className="containerrr">
            <Swiper
              direction={"horizontal"}
              centeredSlides={false}
              pagination={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
              grabCursor={true}
              freeMode={true}
              spaceBetween={50}
              // slidesPerView={6}
              scrollbar={{ draggable: true }}
              dir="ltr"
            >
              <SwiperSlide>
                <div className="card card-content">
                  <div className="card-body">
                    <h5 className="card-title service-titles">
                      <img
                        src="./images/landing/review-img.png"
                        className="card-img-top service-image"
                        alt="no-data"
                      />
                      Beautiful Masterpiece!
                    </h5>
                    <p className="card-text service-subtitle">
                      I recently purchased a handcrafted Tanjore painting from
                      Aanvikshiki Arts and I am blown away by the intricate
                      details and vibrant colors. It's a true masterpiece that
                      adds a touch of elegance to my home. I couldn't be happier
                      with my purchase!
                    </p>
                    <NavLink to={"/"} className="btn btn-primary service-btn">
                      Aadhya
                    </NavLink>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card card-content">
                  <div className="card-body">
                    <h5 className="card-title service-titles">
                      <img
                        src="./images/landing/review-img.png"
                        className="card-img-top service-image"
                        alt="no-data"
                      />{" "}
                      Exquisite Craftsmanship
                    </h5>
                    <p className="card-text service-subtitle">
                      I've always been a fan of traditional Indian art, and the
                      Tanjore painting I received from Aanvikshiki Arts]
                      exceeded my expectations. The craftsmanship is exquisite,
                      and every element is meticulously done. It's a timeless
                      piece that I'll cherish forever.
                    </p>
                    <NavLink to={"/"} className="btn btn-primary service-btn">
                      Eashwari
                    </NavLink>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card card-content">
                  <div className="card-body">
                    <h5 className="card-title service-titles">
                      <img
                        src="./images/landing/review-img.png"
                        className="card-img-top service-image"
                        alt="no-data"
                      />
                      Authentic Beauty
                    </h5>
                    <p className="card-text service-subtitle">
                      As an art enthusiast, I can confidently say that the
                      Tanjore painting I bought from Aanvikshiki Arts is a work
                      of artistry and authenticity. The use of gold foil and
                      precious stones adds a unique touch that makes it stand
                      out. It's a conversation starter in my home!
                    </p>
                    <NavLink to={"/"} className="btn btn-primary service-btn">
                      Aadhira
                    </NavLink>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card card-content">
                  <div className="card-body">
                    <h5 className="card-title service-titles">
                      <img
                        src="./images/landing/review-img.png"
                        className="card-img-top service-image"
                        alt="no-data"
                      />
                      Beautiful Masterpiece!
                    </h5>
                    <p className="card-text service-subtitle">
                      I recently purchased a handcrafted Tanjore painting from
                      Aanvikshiki Arts and I am blown away by the intricate
                      details and vibrant colors. It's a true masterpiece that
                      adds a touch of elegance to my home. I couldn't be happier
                      with my purchase!
                    </p>
                    <NavLink to={"/"} className="btn btn-primary service-btn">
                      Aadhya
                    </NavLink>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card card-content">
                  <div className="card-body">
                    <h5 className="card-title service-titles">
                      <img
                        src="./images/landing/review-img.png"
                        className="card-img-top service-image"
                        alt="no-data"
                      />{" "}
                      Exquisite Craftsmanship
                    </h5>
                    <p className="card-text service-subtitle">
                      I've always been a fan of traditional Indian art, and the
                      Tanjore painting I received from Aanvikshiki Arts]
                      exceeded my expectations. The craftsmanship is exquisite,
                      and every element is meticulously done. It's a timeless
                      piece that I'll cherish forever.
                    </p>
                    <NavLink to={"/"} className="btn btn-primary service-btn">
                      Eashwari
                    </NavLink>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card card-content">
                  <div className="card-body">
                    <h5 className="card-title service-titles">
                      <img
                        src="./images/landing/review-img.png"
                        className="card-img-top service-image"
                        alt="no-data"
                      />
                      Authentic Beauty
                    </h5>
                    <p className="card-text service-subtitle">
                      As an art enthusiast, I can confidently say that the
                      Tanjore painting I bought from Aanvikshiki Arts is a work
                      of artistry and authenticity. The use of gold foil and
                      precious stones adds a unique touch that makes it stand
                      out. It's a conversation starter in my home!
                    </p>
                    <NavLink to={"/"} className="btn btn-primary service-btn">
                      Aadhira
                    </NavLink>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div className="recomended-content">
          <div className="containerrr">
            <h3 className="category-title">
              <span>Recommended</span>
            </h3>
            <div className="recomend-view">
              <Swiper
                direction={"horizontal"}
                centeredSlides={false}
                pagination={false}
                autoplay={{
                  delay: 6500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
                grabCursor={true}
                freeMode={true}
                spaceBetween={50}
                // slidesPerView={6}
                scrollbar={{ draggable: true }}
                dir="ltr"
              >
                {allcategoryListNew.map(item => (
                  <SwiperSlide key={item.id}>
                    <div>

                      <NavLink to={`/Tanjore-painting/${item._id}`}>
                        <div className="card card-box">
                          <div className="card-main">
                            <img
                              src={item.image}
                              className="card-img-top recom-img"
                              alt="..."
                            />
                          </div>

                          <div className="card-body">
                            <h5 className="card-title recomended-title">
                              {item.title}
                            </h5>
                          </div>
                        </div>
                      </NavLink>

                    </div>
                  </SwiperSlide>
                ))}

              </Swiper>
            </div>
          </div>

          {/* 
          <div className="recomended-data">
            <div className="containerrr">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                // navigation={true}
                loop={true}
                navigation={{
                  prevEl: ".custom-swiper-button-prev",
                  nextEl: ".custom-swiper-button-next",
                }}
                modules={[Navigation]}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                }}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="card-contents-inside-main">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/balaji.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">Rs. 2,500</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-contents-inside-main">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/lakshmi-balaji.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">Rs. 2,500</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-contents-inside-main">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/balaji.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">Rs. 2,500</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-contents-inside-main">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/lakshmi-balaji.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">Rs. 2,500</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-contents-inside-main">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/balaji.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">Rs. 2,500</h5>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-contents-inside-main">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/lakshmi-balaji.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">Rs. 2,500</h5>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="custom-swiper-button-prev">
                {}
                <img
                  className="nav-images"
                  src="./images/landing/left-nav.png"
                  alt="no-data"
                />
              </div>
              <div className="custom-swiper-button-next">
                {}
                <img
                  className="nav-images"
                  src="./images/landing/right-nav.png"
                  alt="no-data"
                />
              </div>
            </div>
          </div> */}
        </div>

        <div className="contact-detailslist">
          <div className="containerrr">
            <ul className="contactdetails-ul">
              <li className="contactdetails-li">
                <div className="image-data">
                  <img
                    className="icondata-inside"
                    src="./images/landing/icon-4.png"
                    alt="no-data"
                  />
                </div>
                <h3 className="free-shipping">Free shipping</h3>
                <p className="we-offer">
                  We offer free shipping for purchases over Rs.  1,00,000
                </p>
              </li>
              <li className="contactdetails-li">
                <div className="image-data">
                  <img
                    className="icondata-inside"
                    src="./images/landing/icon-3.png"
                    alt="no-data"
                  />
                </div>
                <h3 className="free-shipping">Top-notch support</h3>
                <p className="we-offer">
                  Any question? Our team is just one click away!
                </p>
              </li>
              <li className="contactdetails-li">
                <div className="image-data">
                  <img
                    className="icondata-inside"
                    src="./images/landing/icon-1.png"
                    alt="no-data"
                  />
                </div>
                <h3 className="free-shipping">Phone contact</h3>
                <p className="we-offer">
                  Monday to Saturday 09:00 AM - 9:00 PM
                </p>
              </li>
              <li className="contactdetails-li">
                <div className="image-data">
                  <img
                    className="icondata-inside"
                    src="./images/landing/icon-2.png"
                    alt="no-data"
                  />
                </div>
                <h3 className="free-shipping">Secure payments</h3>
                <p className="we-offer">
                  Your payment information is processed securely
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="faq-content">
          <div className="containerrr">
            <h3 className="faq-title">
              <span>FAQ</span>
            </h3>

            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="accordion">
                  <div className="accordion-item">
                    <div className="accordion-item-header">1. What sets Aanvikshiki apart in the art industry?</div>
                    <div className="accordion-item-body">
                      <div className="accordion-item-body-content">
                        - Aanvikshiki is distinguished by its passion, experience, and decade-long commitment to the world of art. Our legacy as pioneers in the industry reflects our dedication to infusing every creation with soul, divinity, and faith.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-item-header">2. How experienced is the team of artists at Aanvikshiki?</div>
                    <div className="accordion-item-body">
                      <div className="accordion-item-body-content">
                        - Our team of artists boasts over 40 years of combined experience, bringing a wealth of knowledge and originality to their craft. This experience is a testament to our commitment to delivering high-quality and authentic artworks.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <div className="accordion-item-header">3. What is Tanjore painting, and what makes Aanvikshiki's Tanjore paintings unique?</div>
                    <div className="accordion-item-body">
                      <div className="accordion-item-body-content">
                        - Tanjore painting is a cherished art form from South India. Aanvikshiki's Tanjore paintings stand out due to the magical synergy of artistry and heritage. These hand-driven artworks, featuring religious deities, mythological scenes, and historical narratives, mature with age, becoming a valuable part of the story they tell.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-item-header">4. Can you elaborate on the techniques used by Aanvikshiki's skilled artisans in Tanjore paintings?</div>
                    <div className="accordion-item-body">
                      <div className="accordion-item-body-content">
                        - Our skilled artisans employ a combination of techniques to bring Tanjore paintings to life. These include intricate gold leaf or paint embellishments that add a touch of opulence to the artworks, making them visually stunning and unique.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-item-header">5. How does the aging process enhance Aanvikshiki's Tanjore paintings?</div>
                    <div className="accordion-item-body">
                      <div className="accordion-item-body-content">
                        - Aanvikshiki's Tanjore paintings mature with age, becoming more than just art; they become a valuable part of the story. The aging process adds character and depth to the artworks, making them timeless and cherished pieces.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-item-header">6. Can I request a custom Tanjore painting from Aanvikshiki?</div>
                    <div className="accordion-item-body">
                      <div className="accordion-item-body-content">
                        - Yes, Aanvikshiki welcomes custom orders. Our team can work with you to create a personalized Tanjore painting that reflects your vision and preferences. Contact us for more information on the customization process.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-item-header">7. Are Aanvikshiki's Tanjore paintings suitable for religious or cultural gifting?</div>
                    <div className="accordion-item-body">
                      <div className="accordion-item-body-content">
                        - Absolutely! Many of our Tanjore paintings feature religious deities and mythological scenes, making them ideal for religious and cultural gifting. Each artwork carries a unique blend of artistry and heritage, making it a meaningful and thoughtful gift.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-item-header">8. How can I purchase Tanjore paintings from Aanvikshiki?</div>
                    <div className="accordion-item-body">
                      <div className="accordion-item-body-content">
                        - You can explore and purchase our Tanjore paintings directly from our website at
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="contactus-content">
          <div className="containerrr">
            <div className="row">
              <div className="col-md-6">
                <div className="contactus-left">
                  <h3 className="contact-title">Contact Us</h3>
                  <p className="contact-para">
                    Contact us to explore the world of Aanvikshiki Tanjore art
                    and invest in timeless beauty, spirituality, and
                    craftsmanship.
                  </p>
                  <div className="media contact-lists">
                    <div className="contacticon-main mr-3">
                      <img
                        src="./images/landing/24-hours-support.gif"
                        className="align-self-center contact-icon-img "
                        alt="no-data"
                      />
                    </div>
                    <div className="media-body contactdata-body">
                      <h5 className="contactdata-bodytitle mt-0">Call</h5>
                      <p className="contactdata-bodypara">+91 73000 22559</p>
                    </div>
                  </div>
                  <div className="media contact-lists">
                    <div className="contacticon-main mr-3">
                      <img
                        src="./images/landing/email.gif"
                        className="align-self-center contact-icon-img "
                        alt="no-data"
                      />
                    </div>
                    <div className="media-body contactdata-body">
                      <h5 className="contactdata-bodytitle mt-0">Mail</h5>
                      <p className="contactdata-bodypara">
                        support@aanvikshiki.art
                      </p>
                    </div>
                  </div>
                  <div className="media contact-lists">
                    <div className="contacticon-main mr-3">
                      <img
                        src="./images/landing/location.gif"
                        className="align-self-center contact-icon-img "
                        alt="no-data"
                      />
                    </div>
                    <div className="media-body contactdata-body">
                      <h5 className="contactdata-bodytitle mt-0">
                        Our Address
                      </h5>
                      <p className="contactdata-bodypara">
                        3rd floor, Gowri Arcade, HIG-A-85, Jayabheri Enclave,
                        Gachibowli, Hyderabad, Telangana 500032, India
                      </p>
                    </div>
                  </div>

                  <NavLink
                    className="btn otherquery-btn"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    Get In Touch <i class="fas fa-arrow-right icon-data"></i>
                  </NavLink>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contactus-right">
                  <div className="contactus-image">
                    <img
                      className="image-data"
                      src="./images/landing/hanuman-image.png"
                      alt="no-data"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div>
        <div className="modal contactus-modals fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog contactus-maindata modal-md">
            <div className="modal-content">
              <div className="modal-header contactus-header">
                <h5 className="modal-title contactus-title" id="exampleModalLabel">Fill Inquiry Form</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={modalRef}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="contactus-form" onSubmit={handleFormSubmit}>
                  <div className="form-group">
                    <label className="contactus-label">Full Name</label>
                    <input type="text" className="form-control contactus-input" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={handleNameChange} name="name" />
                  </div>
                  <div className="form-group">
                    <label className="contactus-label">Contact No.</label>
                    <input type="tel" className="form-control contactus-input" onChange={handlePhoneChange} name="phoneNumber" />
                  </div>
                  <div className="form-group">
                    <label className="contactus-label">Email ID</label>
                    <input type="email" className="form-control contactus-input" placeholder="Enter your mail id" onChange={handleEmailChange} name="email" />
                  </div>
                  <div className="form-group">
                    <label className="contactus-label">Description</label>
                    <textarea className="form-control contactus-input" id="exampleFormControlTextarea1" rows="3" placeholder="Description" onChange={handleDescriptionChange} name="description"></textarea>
                  </div>

                  <div className="getbtn-inside">
                    <button type="submit" className="btn btn-primary get-btn">Get a Callback</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



{/* <div class="accordion accordion-flush" id="accordionFlushExample">
<div class="accordion-item">
  <h2 class="accordion-header" id="flush-headingOne">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      1. What sets Aanvikshiki apart in the art industry?
    </button>
  </h2>
  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">
      - Aanvikshiki is distinguished by its passion, experience, and decade-long commitment to the world of art. Our legacy as pioneers in the industry reflects our dedication to infusing every creation with soul, divinity, and faith.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="flush-headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      2. How experienced is the team of artists at Aanvikshiki
    </button>
  </h2>
  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">
      - Our team of artists boasts over 40 years of combined experience, bringing a wealth of knowledge and originality to their craft. This experience is a testament to our commitment to delivering high-quality and authentic artworks.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="flush-headingThree">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      3. What is Tanjore painting, and what makes Aanvikshiki's Tanjore paintings unique?
    </button>
  </h2>
  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">
      - Tanjore painting is a cherished art form from South India. Aanvikshiki's Tanjore paintings stand out due to the magical synergy of artistry and heritage. These hand-driven artworks, featuring religious deities, mythological scenes, and historical narratives, mature with age, becoming a valuable part of the story they tell.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="flush-headingfour">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
      4. Can you elaborate on the techniques used by Aanvikshiki's skilled artisans in Tanjore paintings?
    </button>
  </h2>
  <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">
      - Our skilled artisans employ a combination of techniques to bring Tanjore paintings to life. These include intricate gold leaf or paint embellishments that add a touch of opulence to the artworks, making them visually stunning and unique.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="flush-headingfive">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
      5. How does the aging process enhance Aanvikshiki's Tanjore paintings?
    </button>
  </h2>
  <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">
      - Aanvikshiki's Tanjore paintings mature with age, becoming more than just art; they become a valuable part of the story. The aging process adds character and depth to the artworks, making them timeless and cherished pieces.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="flush-headingsix">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
      6. Can I request a custom Tanjore painting from Aanvikshiki?
    </button>
  </h2>
  <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">
      - Yes, Aanvikshiki welcomes custom orders. Our team can work with you to create a personalized Tanjore painting that reflects your vision and preferences. Contact us for more information on the customization process.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="flush-headingseven">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
      7. Are Aanvikshiki's Tanjore paintings suitable for religious or cultural gifting?
    </button>
  </h2>
  <div id="flush-collapseseven" class="accordion-collapse collapse" aria-labelledby="flush-headingseven" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">
      - Absolutely! Many of our Tanjore paintings feature religious deities and mythological scenes, making them ideal for religious and cultural gifting. Each artwork carries a unique blend of artistry and heritage, making it a meaningful and thoughtful gift.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="flush-headingeight">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
      8. How can I purchase Tanjore paintings from Aanvikshiki?
    </button>
  </h2>
  <div id="flush-collapseeight" class="accordion-collapse collapse" aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">
      - You can explore and purchase our Tanjore paintings directly from our website at [https://aanvikshiki.art/](https://aanvikshiki.art/). Simply browse our collection, select your preferred artworks, and follow the easy checkout process.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="flush-headingnine">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
      9. Does Aanvikshiki offer international shipping for Tanjore paintings?
    </button>
  </h2>
  <div id="flush-collapsenine" class="accordion-collapse collapse" aria-labelledby="flush-headingnine" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">
      - Yes, Aanvikshiki provides international shipping for our Tanjore paintings. During the checkout process, you can select your shipping destination, and our team will ensure safe and timely delivery to your location.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="flush-headingten">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseten" aria-expanded="false" aria-controls="flush-collapseten">
      10. How can I contact Aanvikshiki for further inquiries or assistance?
    </button>
  </h2>
  <div id="flush-collapseten" class="accordion-collapse collapse" aria-labelledby="flush-headingten" data-bs-parent="#accordionFlushExample">
    <div class="accordion-body">- For any additional questions or assistance, you can reach out to Aanvikshiki through the contact information provided on our website. Our dedicated team is ready to help with any queries you may have.
    </div>
  </div>
</div>
</div> */}


export default Landing;
